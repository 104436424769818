import { Input, LabelButton } from '@teamsnap/snap-ui';

export type SecurityUserSearchProps = {
  searchForUser: (email: string, userId: number) => void;
  setEmail: (email: string) => void;
  setUserId: (userId: string) => void;
  email: string;
  userId: string;
}


export function SecurityUserSearch(props: SecurityUserSearchProps) {
  const { setEmail, searchForUser, setUserId, email, userId } = props;
  return (
    <div className="sui-flex sui-flex-col sui-items-center" data-testid="security-user-search-item" style={{}}>
      <h2 className="sui-text-center">User lookup</h2>
      <p className="sui-my-2">Search for user by email or user ID</p>
      <div className="sui-w-full sui-flex sui-flex-row sui-flex-gap sui-items-stretch">
        <Input
          className="sui-m-2"
          name="email"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          placeholder="Search by email"
          allowClear
          value={email}
          type="text"
        />
        <Input
          className="sui-m-2"
          name="user_id"
          onChange={(e) => {
            setUserId(e.target.value);
          }}
          placeholder="Search by user ID"
          value={userId}
          type="number"
        />
        <LabelButton
          data-testid="search-button"
          className="sui-m-2"
          icon="search"
          iconPosition="left"
          labelText="Search"
          sentiment="default"
          onClick={searchForUser}
          disabled={(email === '' && userId === '')}
          size="default"
          variantType="primary"
        />
      </div>
    </div>
  )
}