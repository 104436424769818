import { Banner } from '@teamsnap/snap-ui';
import { Loader } from '@teamsnap/teamsnap-ui';
import { useState } from 'react';
import { DeprecatedBanDetails } from './DeprecatedBanDetails';
import { SecurityBreadcrumbs } from './SecurityBreadcrumbs';
import { SecurityUserSearch } from './SecurityUserSearch';
import { useAppDispatch } from 'state/hooks';
import { loadUser, deleteBan, useSecurityUserSelector, useSecurityUserStateSelector } from 'state/security/userSlice';

enum WarningType {
  None = '',
  TooManyFields = 'Only one of email or user ID should be filled out',
  InvalidEmail = 'Invalid email',
  InvalidUserId = 'Invalid user ID',
  FailedSearch = 'Failed to search for user',
  FailedDeletion = 'Failed to delete ban',
}

/**
 * NOTE: yes, this regex is not perfect, but for our internal admin tool and it's current purpose
 * it's good enough, for now.
 */
const validEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const SecurityUsers = () => {
  const [userId, setUserId] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [warning, setWarning] = useState<WarningType>(WarningType.None);

  const dispatch = useAppDispatch();
  const securityState = useSecurityUserStateSelector();
  const securityData = useSecurityUserSelector();

  async function searchForUser() {
    setWarning(WarningType.None);
    // Ensure that only one of the fields is filled out
    if (email !== '' && userId !== '') {
      setWarning(WarningType.TooManyFields);
      return;
    } else if (userId !== '' && isNaN(parseInt(userId))) {
      setWarning(WarningType.InvalidUserId);
      return;
    } else if (email !== '' && !validEmailRegex.test(email)) {
      setWarning(WarningType.InvalidEmail);
      return;
    }

    dispatch(loadUser({ email, userId: userId ? parseInt(userId, 10) : undefined }));
  }

  async function deleteBanForUser(banId: number) {
    dispatch(deleteBan({ userId: banId }));
  }

  return (
    <div className="sui-p-3 sui-w-full" id="security-user-page">
      <SecurityBreadcrumbs />

      <div
        className="sui-flex sui-flex-col sui-flex-wrap sui-flex-gap sui-mx-auto sui-justify-center sui-w-1/2 sui-items-center"
        style={{ gap: '20px' }}
      >

        <SecurityUserSearch
          searchForUser={searchForUser}
          setEmail={setEmail}
          setUserId={setUserId}
          email={email}
          userId={userId}
        />


        <div className="sui-flex sui-flex-col sui-items-center" style={{}}>
          {securityState.processing && <Loader className="sui-mt-4" type="spin" text="Loading..." />}
          {warning !== WarningType.None && (
            <Banner
              className="sui-mb-3"
              sentiment="negative"
              title={warning}
              description={warning}
              closeFn={() => setWarning(WarningType.None)}
            />
          )}
          {securityState.error && (
            <Banner
              className="sui-mb-3"
              sentiment="negative"
              title="Error"
              description={securityState.error}
            />
          )}
          {securityData && (
            <DeprecatedBanDetails
              ban={securityData.ban}
              deleteBan={deleteBanForUser}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export { SecurityUsers };
