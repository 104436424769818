import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DeprecatedHellbanResponse, SecurityService } from 'core/api';
import { AsyncState } from 'core/types';
import { asyncFulfilledReducer, asyncPendingReducer, handleErrors, useAppSelector } from 'state/hooks';
import { GetSecurityUser } from './types';


const initialState: AsyncState<GetSecurityUser> = {
  data: null,
  processing: false,
  error: false,
  errors: [],
};

export const loadUser = createAsyncThunk('security/getUser', ({email, userId}: {email?: string, userId?: number}) =>
  handleErrors(() => SecurityService.getUser(email, userId)),
);

export const deleteBan = createAsyncThunk('security/deleteBan', ({userId}: {userId: number}) =>
  handleErrors(() => SecurityService.deleteBan(userId)),
);

export const slice = createSlice({
  name: 'security',
  initialState,
  reducers: {
    clearData: (state, action) => {
      state.data = initialState.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadUser.pending, asyncPendingReducer);
    builder.addCase(loadUser.fulfilled, (state, action) => asyncFulfilledReducer(state, action));
  },
});

export const useSecurityUserStateSelector = () => useAppSelector((state) => state.securityUser);
export const useSecurityUserSelector = () => useAppSelector((state) => state.securityUser.data);

export default slice.reducer;
export const { clearData } = slice.actions;
